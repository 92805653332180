// constants
import Web3 from "web3";
import SmartContract from "../../contracts/BookofGates.json";
// log
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await window.ethereum.request({
          method: "net_version",
        });
        //const NetworkData = await SmartContract.networks[networkId];
        // eslint-disable-next-line
        if (networkId == 5) {
          const SmartContractObj = new web3.eth.Contract(
            SmartContract.abi,
            //NetworkData.address,
            "0xE51EB4f5Be0e97163B13799ff9A316B2a8c6912b"
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          window.ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
          // eslint-disable-next-line
        } else if (networkId == 1) {
          const SmartContractObj = new web3.eth.Contract(
            SmartContract.abi,
            //NetworkData.address,
            "0x7c83BC00CE502ED1c87033DC681E67a859a425ae"
            // {
            //   from: "0xcEfa983A6e3a9A7c9662696D9BDa5c3E80B3Bd9f", // default from address
            //   //gasPrice: '2000000', // default gas price in wei, 20 gwei in this case,
            //   gas: "200000", //gas limit
            // }
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          window.ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed("Change network to ETH."));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
