const initialState = {
  loading: false,
  notRevealedUri: "",
  firstPreSaleCost: 0,
  secondPreSaleCost: 0,
  maxSupply: 0,
  firstMaxMintAmountPresale: 0,
  secondMaxMintAmountPresale: 0,
  maxMintAmount: 0,
  totalSupply: 0,
  owner: "",
  cost: 0,
  firstPreSaleDate: "",
  secondPreSaleDate: "",
  publicSaleDate: "",
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      console.log("action.payload=>", action.payload);
      return {
        ...state,
        loading: false,
        notRevealedUri: action.payload.notRevealedUri,
        firstPreSaleCost: action.payload.firstPreSaleCost,
        secondPreSaleCost: action.payload.secondPreSaleCost,
        maxSupply: action.payload.maxSupply,
        firstMaxMintAmountPresale: action.payload.firstMaxMintAmountPresale,
        secondMaxMintAmountPresale: action.payload.secondMaxMintAmountPresale,
        maxMintAmount: action.payload.maxMintAmount,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        owner: action.payload.owner,
        firstPreSaleDate: action.payload.firstPreSaleDate,
        secondPreSaleDate: action.payload.secondPreSaleDate,
        publicSaleDate: action.payload.publicSaleDate,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
