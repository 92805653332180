import React, { useEffect, useState, useRef } from "react";
import bg_video from "./video/bg.mp4";
import "./styles.css";
import AlertDialogSlide from "./FormDialogue";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fetchData } from "../redux/data/dataActions";
import { connect } from "../redux/blockchain/blockchainActions";
import { Button, Typography, Grid } from "@material-ui/core";
import { Snackbar } from "@mui/material";
import AnimatedModal from "../Components/MintModal/MintModal";
import logo from "../assets/logo.png";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  connectButton: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "1.5rem",
    padding: "0.5rem 4rem",
    textTransform: "uppercase",
    border: "none",
    borderRadius: "10rem",
    cursor: "pointer",
    fontFamily: "Integral Bold",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#D8532E",
      transition: ".5s",
    },
    [theme.breakpoints.down("md")]: {
      backgroundColor: "#000",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
        transition: ".5s",
      },
    },
  },
}));

function VideoBackground() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  console.log("blockchain.smartContract===>", blockchain.smartContract);
  console.log("blockchain.account===>", blockchain.account);
  const [mintProgress, setMintProgress] = React.useState(0);
  const data = useSelector((state) => state.data);

  const [snackState, setSnackState] = React.useState({
    state: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.smartContract, dispatch]);

  useEffect(() => {
    if (
      data &&
      data.preSaleDate &&
      data.publicSaleDate &&
      mintProgress < data.maxSupply
    ) {
      const presaleTime = Number(data.preSaleDate);
      const currentTime = Date.now();
      const first10Str = String(currentTime).slice(0, 10);
      const first10Num = Number(first10Str);
      const diff = Math.floor((first10Num - presaleTime) / 60);
      // const minutes = Math.floor(diff / 1000 / 60);
      // console.log("minutes", minutes);
      if (currentTime.toString() > data.preSaleDate) {
        const tempSupply = 2500;

        if (diff * 5 <= tempSupply) {
          setMintProgress(Math.floor(diff) * 5);
        } else if (data.totalSupply <= tempSupply) {
          setMintProgress(tempSupply);
        } else {
          setMintProgress(data.totalSupply);
        }
      }
    }
  }, [data]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackState({
      state: false,
      severity: "",
      message: "",
    });
    blockchain.errorMsg = "";
  };

  const classes = useStyles();

  return (
    <div className="root">
      <video
        autoPlay
        loop
        muted
        className="vedio"
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          // objectFit: "fill",
          objectFit: "cover",
          transform: "translate(-50%, -50% )",
          zIndex: "-1",
        }}
      >
        <source src={bg_video} type="video/mp4" />
      </video>

      <div className="btn-container">
        <div>
          {/* <div
            style={{
              fontFamily: "Integral Bold",
              textAlign: "center",
              color: "#fff",
            }}
          >
            <h2>{mintProgress}/5555</h2>
          </div> */}
          {blockchain.account === "" || blockchain.smartContract === null ? (
            <div>
              <Button
                // variant="contained"
                // size="small"
                // color="primary"
                className={classes.connectButton}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                }}
              >
                Connect Wallet
              </Button>
              {blockchain.errorMsg !== "" ? (
                <Snackbar
                  open={blockchain.errorMsg !== ""}
                  autoHideDuration={6000}
                  onClose={handleCloseSnack}
                  message="Note archived"
                  //action={action}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity="info"
                    sx={{ width: "100%" }}
                    // style={{ backgroundColor: "#2E0E12" }}
                  >
                    {blockchain.errorMsg}
                  </Alert>
                </Snackbar>
              ) : null}
            </div>
          ) : (
            <div>
              <AnimatedModal />
            </div>
          )}
        </div>
        {/* <AlertDialogSlide /> */}
      </div>
      <div className="footer-desc">
        <div>
          <p className="copyright-para" style={{ fontFamily: "Integral Bold" }}>
            <p>&copy; Book of Gates</p>
          </p>
        </div>
        <div className="footer-icons">
          <div className="Img-container">
            <a
              href="https://www.instagram.com/bookofgatesofficial/"
              target="_blank"
            >
              <img className="icon-img" src="./Assets/1 (1).png" alt="" />
            </a>
          </div>
          <div className="Img-container">
            <a href="https://twitter.com/bookofgates1" target="_blank">
              <img src="./Assets/1 (2).png" className="icon-img" alt="" />
            </a>
          </div>
          <div className="Img-container">
            <a href="https://discord.com/invite/aUKY8zVQVg" target="_blank">
              <img src="./Assets/1 (3).png" className="icon-img" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoBackground;
