import { Container, CssBaseline } from "@material-ui/core";
import "./app.css";
import VideoBackground from "./Components/VideoBackground";
import "./Components/styles.css";
function App() {
  return (
    <div className="App">
      <Container>
        <VideoBackground />
      </Container>
    </div>
  );
}

export default App;
