// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let notRevealedUri = await store
        .getState()
        .blockchain.smartContract.methods.notRevealedUri()
        .call();

      let firstPreSaleCost = await store
        .getState()
        .blockchain.smartContract.methods.firstPreSaleCost()
        .call();

      let secondPreSaleCost = await store
        .getState()
        .blockchain.smartContract.methods.secondPreSaleCost()
        .call();

      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();

      let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call();

      let firstMaxMintAmountPresale = await store
        .getState()
        .blockchain.smartContract.methods.firstMaxMintAmountPresale()
        .call();

      let secondMaxMintAmountPresale = await store
        .getState()
        .blockchain.smartContract.methods.secondMaxMintAmountPresale()
        .call();

      let maxMintAmount = await store
        .getState()
        .blockchain.smartContract.methods.maxMintAmount()
        .call();

      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      let firstPreSaleDate = await store
        .getState()
        .blockchain.smartContract.methods.firstPreSaleDate()
        .call();

      let secondPreSaleDate = await store
        .getState()
        .blockchain.smartContract.methods.secondPreSaleDate()
        .call();

      let publicSaleDate = await store
        .getState()
        .blockchain.smartContract.methods.publicSaleDate()
        .call();

      let owner = await store
        .getState()
        .blockchain.smartContract.methods.owner()
        .call();

      dispatch(
        fetchDataSuccess({
          notRevealedUri,
          firstPreSaleCost,
          secondPreSaleCost,
          cost,
          maxSupply,
          totalSupply,
          firstMaxMintAmountPresale,
          secondMaxMintAmountPresale,
          maxMintAmount,
          firstPreSaleDate,
          secondPreSaleDate,
          publicSaleDate,
          owner,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
